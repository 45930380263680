import React from 'react';
import './footer.style.scss';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { IoCall } from 'react-icons/io5';
import { HiMail } from 'react-icons/hi';
import { AiFillYoutube, AiFillFacebook, AiFillInstagram, AiFillLinkedin, AiFillTwitterSquare } from 'react-icons/ai';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

const Footer = () => {

   const history = useHistory()

    return(
        <footer className='footer-container'>
           <div className='top-section'>
                <div className='map-container footer-subcontainer'>
                    <iframe title="Asra" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3673.6728601054697!2d76.04860631536233!3d22.962272124340288!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3963178ffffffffd%3A0x4d77d4a9ce1df0cd!2sASRA%20Samajik%20Lok%20Kalyan%20Samiti!5e0!3m2!1sen!2sin!4v1617443564356!5m2!1sen!2sin" width="300" height="300" style={{border:'none', borderRadius:'10px'}}  allowfullscreen="" loading="lazy"></iframe>
                </div>
                <div className='react-us-container footer-subcontainer'>
                    <p className='footer-title' >Reach Us</p>

                    <div className='footer-address footer-entity'>
                       <FaMapMarkerAlt color="#444444" size="22" /> 
                       <p className='footer-text'>
                        Asra Samajik Lok Kalyan Samiti,<br/>26 Badridham Nagar,<br/>Dewas, Madhya Pradesh<br/>455001
                       </p>
                    </div>

                    <div className='footer-address footer-entity'>
                       <IoCall color="#444444" size="22" /> 
                       <p className='footer-text'>
                       +91-9425306366<br/>
                       07272-351001
                       </p>
                    </div>

                    <div className='footer-address footer-entity'>
                       <HiMail color="#444444" size="22" /> 
                       <p className='footer-text'>
                       asra@asrango.org
                       </p>
                    </div>
                    
                </div>
                <nav className='navigation-container footer-subcontainer'>
                    <p className='footer-title'>Navigation</p>
                    <p className='footer-text footer-nav-text ' onClick={() => {window.scrollTo(0, 0)}}>Home</p>
                    <a className='footer-text footer-nav-text ' href='/#about-us'>About Us</a>
                    <a className='footer-text footer-nav-text ' href='/#covid-19'>Covid-19</a>
                    <a className='footer-text footer-nav-text ' href="/#our-projects">Our Projects</a>
                    <Link className='footer-text footer-nav-text ' to='/governance'>Governance</Link>
                    <Link className='footer-text footer-nav-text ' to='/contact-us'>Contact Us</Link>
                    <p className='footer-text footer-nav-text '>Donate Now</p>
                </nav>
                <div className='social-links-container footer-subcontainer'>
                    <p className='footer-title' >Follow Us</p>
                    
                    <div className='footer-address footer-entity' onClick={() => {window.open("https://www.youtube.com/channel/UCON4z5imuxbqfoavo5NGQSg", "_blank")}}>
                       <AiFillYoutube color="#444444" size="22" /> 
                       <p className='footer-text '>YouTube</p>
                    </div>

                    <div className='footer-address footer-entity' onClick={() => {window.open("https://www.facebook.com/ASRA-Samajik-Lok-Kalyan-Samiti-KRC-349963012039319", "_blank")}}>
                       <AiFillFacebook color="#444444" size="22" /> 
                       <p className='footer-text '>Facebook</p>
                    </div>

                    <div className='footer-address footer-entity'>
                       <AiFillInstagram color="#444444" size="22" /> 
                       <p className='footer-text '>Instagram</p>
                    </div>

                    <div className='footer-address footer-entity'>
                       <AiFillLinkedin color="#444444" size="22" /> 
                       <p className='footer-text '>LinkedIn</p>
                    </div>

                    <div className='footer-address footer-entity'>
                       <AiFillTwitterSquare color="#444444" size="22" /> 
                       <p className='footer-text '>Twitter</p>
                    </div>

                    <div className='footer-address footer-entity' onClick={() => {window.open("mailto:asra@asrango.org")}}>
                       <HiMail color="#444444" size="22" /> 
                       <p className='footer-text '>Mail</p>
                    </div>
                </div>
           </div>

           <div className='bottom-section'>
            <p>&copy; Copyright 2021 Asra Samajik Lok Kalyan Samiti</p>
           </div>

        </footer>
    )
}

export default Footer;