// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        key:'mission',
        title:"Mission",
        content:<p>To foster integrated community development program through multi-disciplinary programs and people's participation.<br/><br/>To improve the health status of women through sustainable health facilities and better health care, sanitation, and environment, through self-help community action and involvement in the local civic system.<br/><br/>To uplift the Socioeconomic conditions of low-income families through the establishment of permanent Centers for training, assisting women and girls in income-generating activities, through vocational training.</p>

    },
    {
        key:'vision',
        title:"Vision",
        content:<p>To empower the communities towards sustaining the development process leading to their total well-being. </p>
        
    },
    {
        key:'goal',
        title:"Goal",
        content:<p>To ensure the participation of the poor and marginalized in sustainable development Processes through the collective decision making and facilitating the emergence of democratic leadership and institutions among people and innovative means that’s helped the nation to be the great economic power of the world.</p>
        
    }
]