import meal from '../../assets/meal 1.png';
import grain from '../../assets/grain.png';
import shampoo from '../../assets/shampoo.png';
import medicines from '../../assets/medicine.png';
import thermometer from '../../assets/thermometer.png';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        key:'meal',
        icon:meal,
        title:'Free meals for survival',
        subtitle:'To the homeless in night shelters, on roads, elders in old age homes, in urban slums and migrant daily wage labourers who have nowhere and no one to turn to. '
    },
    {
        key:'grain',
        icon:grain,
        title:'Family Survival Kits',
        subtitle:' Which include basic ration like Rice, Wheat, Spices, cooking oil etc. along with Corona protective gear like masks, bathing and washing soaps etc '
    },
    {
        key:'shampoo',
        icon:shampoo,
        title:'Protective Hygiene Kits',
        subtitle:'For the villagers and their families, to fight Covid 19 in which items like sanitizer, mask, and hand wash etc. were distributed'
    },
    {
        key:'medicines',
        icon:medicines,
        title:'Free Medicines and Healthcare',
        subtitle:'Medicines and healthcare facilities provided to migrant, poor and needy peoples'
    },
    {
        key:'thermometer',
        icon:thermometer,
        title:'Pulse Oximeters and Thermal Guns ',
        subtitle:'Asra donated more than 100 Pulse Oximeters and thermal guns for all Corona warriors and front line workers of health and police department'
    },
    
]