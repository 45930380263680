import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import ProjectArch from '../project-arch/project-arch.component';
import SectionTitle from '../section-title/section-title.component';
import './projects.style.scss';
import projectslist from './projectslist';


const Projects = () => {
    const history = useHistory();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setScreenWidth(window.innerWidth);
        })
    },[])
    
    const handleButtonClick = (key) => {
        history.push(`project/${key}`)    
    }

    return(
        <div className='projects-container' id='our-projects' >
            <SectionTitle title="Our Projects" />
            <div className='projects-content'>
                {
                    projectslist.map(({title, subtitle1, subtitle2, subtitle3, key, image }, index) => <ProjectArch image={image} key={key} handleButtonClick={() => {handleButtonClick(key)}} subtitle1={subtitle1} subtitle2={subtitle2} subtitle3={subtitle3} title={title} inverted = {index%2 === 0?true:false} />)
                }
            </div>
            <div className='uday-documentery'>
                {
                    screenWidth <= 671
                    ?
                    <iframe width="100%" height="315" style={{borderRadius:'10px'}} src="https://www.youtube.com/embed/Y8A8uthP844" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    :
                    <iframe width="100%" height="615" style={{borderRadius:'10px'}} src="https://www.youtube.com/embed/Y8A8uthP844" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                }
               
            </div>
        </div>
    )
}

export default Projects