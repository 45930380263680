// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        key:"one",
        url:'https://firebasestorage.googleapis.com/v0/b/asra-ngo.appspot.com/o/news%201.jpg?alt=media&token=c48ad1df-e99f-41ed-abb5-3dd567d1401c'
    },
    {
        key:"two",
        url:'https://firebasestorage.googleapis.com/v0/b/asra-ngo.appspot.com/o/news%202.jpg?alt=media&token=de78aadc-bb96-44b6-996e-8520e97e207a'
    },
    {
        key:"three",
        url:'https://firebasestorage.googleapis.com/v0/b/asra-ngo.appspot.com/o/news%203.jpeg?alt=media&token=5a6c9f34-afef-4ffa-8503-8f92afdff70c'
    },
    {
        key:"four",
        url:'https://firebasestorage.googleapis.com/v0/b/asra-ngo.appspot.com/o/news%204.jpeg?alt=media&token=94d17a19-722d-4528-82d5-ad96f814e9d8'
    },
    {
        key:"five",
        url:'https://firebasestorage.googleapis.com/v0/b/asra-ngo.appspot.com/o/news%205.jpeg?alt=media&token=cd8976c5-8904-46d3-8d17-f0960e4a385c'
    },
    {
        key:"six",
        url:'https://firebasestorage.googleapis.com/v0/b/asra-ngo.appspot.com/o/news%206.jpeg?alt=media&token=c92914a7-2067-48ed-bc5a-e997b6e10af9'
    },
    {
        key:"seven",
        url:'https://firebasestorage.googleapis.com/v0/b/asra-ngo.appspot.com/o/news%207.jpeg?alt=media&token=36934d14-ad55-4e2f-9532-b1456fad0d9e'
    },

]