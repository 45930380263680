import React, { useState } from 'react';
import './hero.style.scss';


// test
import testFirangi from '../../assets/test-firangi.webp';
import HomepageCarouselComponent from '../home-page_carousel/home-carousel.component';


const Hero = _ => {

    const [ currentTab, setCurrentTab ] = useState({ image: testFirangi, tag:"Nature has blessed us with so much, and we must save and protect her in return", project:"firangi visit" });
    const image = [{
                        image:"https://firebasestorage.googleapis.com/v0/b/asra-ngo.appspot.com/o/home-carousel%2F1b.jpg?alt=media&token=0778ae5c-8af3-41db-8f88-fa364feba22f", 
                        tag:"ASRA - Association for Social Reform and Action"
                    }, 
                    {
                        image:"https://firebasestorage.googleapis.com/v0/b/asra-ngo.appspot.com/o/home-carousel%2F2b.jpg?alt=media&token=77cf9261-c6e7-4795-bd52-4913754053ff", 
                        tag:"Avoid the Scare…Be Aware"
                    }, 
                    {
                        image:"https://firebasestorage.googleapis.com/v0/b/asra-ngo.appspot.com/o/home-carousel%2F3b.jpg?alt=media&token=e7d45744-1321-4a0c-9cab-de210115d259", 
                        tag:"Childhood means fun unlimited...Bounteous shower of love and care"
                    }, 
                    {
                        image:"https://firebasestorage.googleapis.com/v0/b/asra-ngo.appspot.com/o/home-carousel%2F4b.jpg?alt=media&token=82bb025e-83e8-4869-a343-6eeb16f44eee", 
                        tag:"Generate sustainable livelihoods for rural women’s"
                    },
                    {
                        image:"https://firebasestorage.googleapis.com/v0/b/asra-ngo.appspot.com/o/home-carousel%2F5b.jpg?alt=media&token=409d5c07-0710-4222-b26d-6180668a5c89",
                        tag:"Nature has blessed us with so much and it is our duty to save and protect her in return"
                    },
                    {
                        image:"https://firebasestorage.googleapis.com/v0/b/asra-ngo.appspot.com/o/home-carousel%2F6b.jpg?alt=media&token=1f72c4ac-4856-4c8c-9bc0-5348a36b8a95",
                        tag:"Ensuring Women Empowerment"
                    },
                    {
                        image:"https://firebasestorage.googleapis.com/v0/b/asra-ngo.appspot.com/o/home-carousel%2F7.png?alt=media&token=e8fa4872-20e1-451f-9a3c-2cd9f6a26aff",
                        tag:"Encouraging and Motivating Children"
                    },
                    {
                        image:"https://firebasestorage.googleapis.com/v0/b/asra-ngo.appspot.com/o/home-carousel%2F8.jpg?alt=media&token=d0a44272-09dd-4077-ac79-48dba281584d",
                        tag:"Award for tirelessly serving the Nation"
                    },
                    {
                        image:"https://firebasestorage.googleapis.com/v0/b/asra-ngo.appspot.com/o/home-carousel%2F9b.jpg?alt=media&token=e342a7d9-3494-48d0-947d-f567e9be9eb3",
                        tag:"Work with Siemens Gamesa"
                    },
                    {
                        image:"https://firebasestorage.googleapis.com/v0/b/asra-ngo.appspot.com/o/home-carousel%2F10b.jpg?alt=media&token=80d6f842-0bdf-4c99-84ee-57404129006a",
                        tag:"Siemens Gamesa Soccer League Program"
                    }
                ]
    return(
        <div className='hero-container'>
            {/* <div className='background-image-container'>
                <img className='background-image' src={currentTab.image} alt={currentTab.project}  />
            </div>
            <div className='carosel-container'>
                <img className='carosel-image' src={currentTab.image} alt={currentTab.project}  />
                <p className='carosel-tag'>{currentTab.tag}</p>
            </div> */}

            <HomepageCarouselComponent imageUrls={image} />
        </div>

    )
}

export default Hero