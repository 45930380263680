import React,  { createRef, useState } from 'react';
import { useHistory } from 'react-router';
import Collage from '../collage/collage.component';
import CovidPackage from '../covid-packages/covid-pacakage.component';
import CovidStats from '../covid-stats/covid-stats.component';
import SectionTitle from '../section-title/section-title.component';
import covid19Stats from './covid-19-stats';
import './covid-19.styles.scss';
import covidList from './covid-list';
import covidNewsClipings from './covid-news-clipings';

const Covid19 = _ => {

    const [onHoverCondition, setOnHoverCondition] = useState("");
    const history = useHistory();
    let isDown = false;
    let startX;
    let scrollLeft; 
    const slider = createRef();
    

    const handleMouseDown = (e) => {
        isDown = true;
        // slider.current.classList.add('active');
        startX = e.pageX - slider.current.offsetLeft;
        scrollLeft = slider.current.scrollLeft;
    }

    const handleMouseUp = (e) => {
        isDown = false;
        // slider.classList.remove('active');
    }

    const handleMouseMove = (e) => {
        e.preventDefault();
        if(!isDown) return;
       
        const x = e.pageX - slider.current.offsetLeft;
        const walk = (x - startX); //scroll-fast
        slider.current.scrollLeft = scrollLeft - walk;
      
       
    }
    
    return(
        <div className='covid-19-container' id="covid-19" >
            <SectionTitle title="Covid-19" />
            <p className='section-one'>
                The Government of India declared COVID 19 as a national disaster and imposed a complete nationwide lockdown to contain the outbreak. This brought turmoil in the lives of millions who lost their livelihoods that created serious implications on their lives and survival.  The loss of income for people already living perilously close to the margins of survival further propelled them into abject poverty. In a way, it is like, besides the fear of getting infected, the negative impact of Covid-19 on the lives of marginalized is hunger and poverty.
            </p>

            <p className='section-two'>
                In this context, ASRA has developed a strategy in consultation with its existing teams in different locations viz. state, district, block, villages, and slums as well as its large cadre of volunteers at the grassroots that reached out to people for understanding their existing challenges and needs
            </p>

            <div className='covid-giveaway-container'>
                {
                    covidList.map(({title, icon, subtitle, key}) => <CovidPackage title={title} subtitle={subtitle} icon={icon} key={key}  />)
                }
            </div>

            <Collage />

            <div className='covid-stats-container'>
                {
                    covid19Stats.map(({title, value, key}) => <CovidStats title={title} value={value} key={key} />)
                }
            </div>

            <div className='media-container'>
                <img className='covid-banner' src="https://firebasestorage.googleapis.com/v0/b/asra-ngo.appspot.com/o/Asra%20Covid%20Banner%20(1).png?alt=media&token=48eef52a-a4d7-4369-a3a3-17d95bd8e698" alt="28100 Vaccination by asra in Alirajpur, Dewas, Indore, Rajgarh" loading='lazy' />
               <div className='media-content-preview'>
                {
                    onHoverCondition
                    ?
                    <img style={{width:"80%", height:415, borderRadius:"10px", objectFit:'contain'}} src={onHoverCondition} alt={'asra-covid-news'} />
                    :
                    <iframe width="760" height="415" style={{borderRadius:'10px'}} src="https://www.youtube.com/embed/SQ-XUVKVXAc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                }
                
               </div>
              
               <div className='media-list' ref={slider} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove}  >
                    {
                        covidNewsClipings.map(({key, url}) => <img  className='news-clips' key={key} src={url} alt='asra-covid-news'  onMouseOver={() => {setOnHoverCondition(url)}} onMouseOut={() => {setOnHoverCondition("")}}  />)
                    }
                </div>
              
            </div>
            
            <p className='know-more' onClick={() => {history.push({pathname:"report",state:"https://firebasestorage.googleapis.com/v0/b/asra-ngo.appspot.com/o/ASRA%20Covid%2019%20Report.pdf?alt=media&token=43065158-a875-4940-a699-cbb7b2388b3a"})}} >... Know More</p>

        </div>  
    )
}

export default Covid19

