
import './App.css';
import { Redirect, Route, Switch } from 'react-router';
import HomePage from './pages/homepage/homepage.component';
import PdfView from './pages/pdf-viewer/pdfviewer.component';
import ContactUs from './pages/contact-us/contact-us.component';
import React from 'react';
import ProjectDetails from './pages/project-details/project-details.component';
import Governance from './pages/governance/governance.component';


function App() {

  
  

  return (
    <div className="App" >
        <Switch>
          <Route path='/' exact component={HomePage} />
          <Route path='/report' exact component={PdfView} />
          <Route path='/contact-us' exact component={ContactUs} />
          <Route path='/governance' exact component={Governance} />
          <Route path='/project/:projectId' component={ProjectDetails} />
          
        </Switch>
    </div>
  );
}

export default App;


// "rules": {
//   ".read": "now < 1620153000000",  // 2021-5-5
//   ".write": "now < 1620153000000",  // 2021-5-5
// }