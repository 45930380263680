import React from 'react';
import './collage.style.scss';

//left half images
import lh_one from '../../assets/collage/lh_00.png';
import lh_two from '../../assets/collage/lh_01.png';
import lh_three from '../../assets/collage/lh_10.png';
import lh_four from '../../assets/collage/lh_11.png';
import lh_five from '../../assets/collage/lh_13.png';

//right half images
import rh_one from '../../assets/collage/rh_00.png';
import rh_two from '../../assets/collage/rh_01.png';
import rh_three from '../../assets/collage/rh_02.png';
import rh_four from '../../assets/collage/rh_11.png';
import rh_five from '../../assets/collage/rh_12.png';
import rh_six from '../../assets/collage/rh_22.png';


const Collage = ( ) => {
    return(
        <div className='collage-container'>
            <div className='left-half'>
                <img className='lh_00 collage_img' onClick={() => {window.open("lh_00.png", "_blank")}} src={lh_one} alt={"asd"}  />
                <img className='lh_01 collage_img' src={lh_two} alt={"asd"}  />
                <img className='lh_10 collage_img' src={lh_three} alt={"asd"}  />
                <img className='lh_11 collage_img' src={lh_four} alt={"asd"}  />
                <img className='lh_13 collage_img' src={lh_five} alt={"asd"}  />
            </div>

            <div className='right-half'>
                <img className='rh_00 collage_img' src={rh_one} alt={"asd"}  />
                <img className='rh_01 collage_img' src={rh_two} alt={"asd"}  />
                <img className='rh_10 collage_img' src={rh_three} alt={"asd"}  />
                <img className='rh_11 collage_img' src={rh_four} alt={"asd"}  />
                <img className='rh_13 collage_img' src={rh_five} alt={"asd"}  />
                <img className='rh_13 collage_img' src={rh_six} alt={"asd"}  />
            </div>
        </div>
    )
}

export default Collage