import React , { useEffect, useState } from 'react';
import About from '../../components/about/about.component';
import Covid19 from '../../components/covid-19/covid-19.component';
import Footer from '../../components/footer/footer.component';
import Hero from '../../components/hero/hero.component';
import Navigation from '../../components/Navigation/navigation.component';
import Partners from '../../components/partners/partners.component';
import Projects from '../../components/projects/projects.component';
import './homepage.style.scss';

const HomePage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return(
        <div className='home-page-container'>
           
            <Navigation />
            <Hero />
            <About />
            <Covid19 />
            <Projects />
            <Partners />
            <Footer /> 
        </div>
    )
}

export default HomePage;