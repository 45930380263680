import React from 'react';
import './section-title.style.scss';

const SectionTitle = ({title, hyperLinkTo}) => {
    
    return(
        <div className='section-title-container' id={hyperLinkTo?hyperLinkTo:null} >
            <p className='title' >{title}</p>
        </div>
    )
}
export default SectionTitle