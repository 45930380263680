import React from "react";
import "./governance.style.scss"
import Navigation from "../../components/Navigation/navigation.component";
import banner from "../../assets/governance_image.jpg"
import Footer from "../../components/footer/footer.component";
import audit1 from "../../assets/Audit 2021-22 Comp.pdf"
import audit2 from "../../assets/Audit 2020-21_ comp.pdf"
import audit3 from "../../assets/Audit 2019-20_ comp.pdf"
import fcra1 from "../../assets/FC 4 2021-22.pdf"
import fcra2 from "../../assets/FC 4 2020-21.pdf"
import fcra3 from "../../assets/FC4 2019-20.pdf"
import management from "../../assets/List of managing commt.pdf"
import bod from "../../assets/BOD.pdf"

const Governance = () => {

    return(
        <div className="governance-container">
            <Navigation />

            <img src={banner} className="banner" />

            <div className="content-container">
                <div className="set-container">
                    <p className="heading">Annual Reports</p>
                    <div className="list-date-container">
                        <div className="bar"></div>
                        <p className="sub-heading">2020-2023</p>

                        <div className="doc-list-container">
                            <div className="doc-detail-container">
                                <p className="date">2022-2023</p>
                                <a href={"https://firebasestorage.googleapis.com/v0/b/asra-ngo.appspot.com/o/Annual%20Report%202022-23.pdf?alt=media&token=173fb56c-326b-4854-be68-27e99d2d308c"} target = "_blank" className="file-name">Annual Report 2022-2023.pdf</a>
                            </div>
                            <div className="doc-detail-container">
                                <p className="date">2021-2022</p>
                                <a href={"https://firebasestorage.googleapis.com/v0/b/asra-ngo.appspot.com/o/Annual%20Report%202021-22.pdf?alt=media&token=19937e64-2483-48ff-907b-71981a642443"} target = "_blank" className="file-name">Annual Report 2021-2022.pdf</a>
                            </div>
                            <div className="doc-detail-container">
                                <p className="date">2020-2021</p>
                                <a href={"https://firebasestorage.googleapis.com/v0/b/asra-ngo.appspot.com/o/Annual%20Report%202020-21.pdf?alt=media&token=9340f94d-c1e5-4d31-8ebd-d1ca02ba5efa"} target = "_blank" className="file-name">Annual Report 2020-2021.pdf</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="set-container">
                    <p className="heading">FCRA</p>
                    <div className="list-date-container">
                        <div className="bar"></div>
                        <p className="sub-heading">2019-2022</p>

                        <div className="doc-list-container">
                            <div className="doc-detail-container">
                                <p className="date">2021-2022</p>
                                <a href={fcra1} target = "_blank" className="file-name">FC 4 2021-22.pdf</a>
                            </div>
                            <div className="doc-detail-container">
                                <p className="date">2020-2021</p>
                                <a href={fcra2} target = "_blank" className="file-name">FC 4 2020-21.pdf</a>
                            </div>
                            <div className="doc-detail-container">
                                <p className="date">2019-2020</p>
                                <a href={fcra3} target = "_blank" className="file-name">FC4 2019-20.pdf</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="set-container">
                    <p className="heading">Board/Management</p>
                    <div className="list-date-container">
                        <div className="bar"></div>
                        <p className="sub-heading">2023</p>

                        <div className="doc-list-container">
                            <div className="doc-detail-container">
                                <p className="date">Management Committee</p>
                                <a href={"https://firebasestorage.googleapis.com/v0/b/asra-ngo.appspot.com/o/List%20of%20Managing%20Committee.pdf?alt=media&token=4be252c3-ee22-4dac-b52e-3f5ff7e9a345"} target = "_blank" className="file-name">List of Managing Committee.pdf</a>
                            </div>
                            <div className="doc-detail-container">
                                <p className="date">Board of Directors</p>
                                <a href={"https://firebasestorage.googleapis.com/v0/b/asra-ngo.appspot.com/o/BOD%20List%20%202024.pdf?alt=media&token=9356c8bf-c791-40a9-864a-246f3097f6c1"} target = "_blank" className="file-name">BOD.pdf</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Governance