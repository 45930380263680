import firebase from 'firebase'

var firebaseConfig = {
    apiKey: "AIzaSyDueUVfMqmihIev7d8uI8V7HiNQ3uRQXws",
    authDomain: "asra-ngo.firebaseapp.com",
    databaseURL: "https://asra-ngo-default-rtdb.firebaseio.com",
    projectId: "asra-ngo",
    storageBucket: "asra-ngo.appspot.com",
    messagingSenderId: "273427696845",
    appId: "1:273427696845:web:70a042d39d6e93e5cced84",
    measurementId: "G-H3TKNN1KWY"
};

firebase.initializeApp(firebaseConfig)

export const firebaseDatabase = firebase.database();