import React, { useEffect, useState } from 'react'
import './constact-us.style.scss'
import MobileNavigation from '../../components/Navigation/mobile-navigation.component';
import Navigation from '../../components/Navigation/navigation.component';
import { AiFillYoutube, AiFillFacebook, AiFillInstagram, AiFillLinkedin, AiFillTwitterSquare } from 'react-icons/ai';
import { HiMail } from 'react-icons/hi';
import contactUsSample from '../../entities/contact-us-sample';
import { firebaseDatabase } from '../../firebase/firebase-handler';

const ContactUs = () => {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [contactUsQuery, setContactUsQuery] = useState(contactUsSample)
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener('resize', () => {
            setScreenWidth(window.innerWidth)
        })
    },[])

    const submitForm = async () => {

        var phoneRegex = /^\d{10}$/;

        if (contactUsQuery.firstName === "") {
            alert("Please enter First Name.")
            return
        }

        if (contactUsQuery.phoneNumber.length !== 10 || !contactUsQuery.phoneNumber.match(phoneRegex)) {
            alert("Please enter a valid 10 digit Phone Number.")
            return
        }

        if (contactUsQuery.emailId === "") {
            alert("Please enter your Email-Id.")
            return
        }

        if (contactUsQuery.message === "") {
            alert("Please enter a Message.")
            return
        }

        setIsSubmitting(true)
        var currentDate = new Date(); 
        contactUsQuery.date = currentDate.getDate() + "/" + (currentDate.getMonth()+1)  + "/" + currentDate.getFullYear() + " " + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds();
        var key = firebaseDatabase.ref("CONTACT_US_QUERIES").push().key;
        contactUsQuery.key = key;
        await firebaseDatabase.ref("CONTACT_US_QUERIES").child(key).set(contactUsQuery);
        alert("Thank you for contacting us. Your query is saved and we will reach you within 48 hours.")
        setContactUsQuery(contactUsSample)
        setIsSubmitting(false)
    }

    return(
        <div className="contact-us-container">
            {
                screenWidth >= 837
                ?
                <Navigation />
                :
                <MobileNavigation />

            }

            <div className="contact-us-content">
                <div className="contact-us-form-container">
                    <p className="form-heading">Reach out to us!</p>
                    <p className="form-sub-heading">We would love to hear your suggestions and answer your questions.</p>

                    <div className="contact-us-form">
                        <input className="input-field" value={contactUsQuery.firstName} type="text" placeholder="First Name" onChange={event => setContactUsQuery({...contactUsQuery, firstName:event.target.value})}/> 
                        <input className="input-field" value={contactUsQuery.lastName} type="text" placeholder="Last Name" onChange={event => setContactUsQuery({...contactUsQuery, lastName:event.target.value})}/> 
                        <input className="input-field" value={contactUsQuery.phoneNumber} type="tel" maxLength={10} placeholder="Phone Number" onChange={event => setContactUsQuery({...contactUsQuery, phoneNumber:event.target.value})}/> 
                        <input className="input-field" value={contactUsQuery.emailId} type="email" placeholder="Email-Id" onChange={event => setContactUsQuery({...contactUsQuery, emailId:event.target.value})}/> 
                        <input className="input-field address-field" value={contactUsQuery.address} type="text" placeholder="Address" onChange={event => setContactUsQuery({...contactUsQuery, address:event.target.value})}/> 
                        <textarea className="input-field message-field" value={contactUsQuery.message} type="text" placeholder="Message" onChange={event => setContactUsQuery({...contactUsQuery, message:event.target.value})}/> 
                    
                        <button className="button contact-us-button" onClick={() => {if(!isSubmitting)submitForm()}} style={isSubmitting?{backgroundColor:"#bababa"}:null}>Submit</button>
                    </div>

                    
                </div>

                <div className="head-office-container">
                    <p className="form-heading">Head Office</p>
                    <p className='head-office-details'>
                        Asra Samajik Lok Kalyan Samiti,<br/>26 Badridham Nagar,<br/>Dewas, Madhya Pradesh<br/>455001
                    </p>
                    <p className='head-office-details'>
                       +91-9425306366<br/>
                       07272-351001
                    </p>
                    <p className='head-office-details'>
                       asra@asrango.org
                    </p>

                    <div className="social-links-container">
                        <AiFillYoutube color="#444444" size="22" /> 
    
                        <AiFillFacebook color="#444444" size="22" /> 
       
                        <AiFillInstagram color="#444444" size="22" /> 
      
                        <AiFillLinkedin color="#444444" size="22" /> 
       
                        <AiFillTwitterSquare color="#444444" size="22" />

                        <HiMail color="#444444" size="22" /> 
                    </div>
                </div>

            </div>

            <div className="contact-us-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3673.6728601054656!2d76.04860631535325!3d22.962272124340444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3963178ffffffffd%3A0x4d77d4a9ce1df0cd!2sASRA%20Samajik%20Lok%20Kalyan%20Samiti!5e0!3m2!1sen!2sin!4v1617555471145!5m2!1sen!2sin" width="100%" height="450" style={{border:"none"}} allowfullscreen="" loading="lazy"></iframe>
            </div>

            <div className='bottom-section'>
                <p>&copy; Copyright 2021 Asra Samajik Lok Kalyan Samiti</p>
            </div>
        </div>
    )
}

export default ContactUs
