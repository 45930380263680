// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        key:'oxy-meter',
        value:'100+',
        title:'Oxi-Meters'
    },
    {
        key:'ppe-kits',
        value:'300+',
        title:'PPE Kits'
    },
    {
        key:'fever-clinic',
        value:'20+',
        title:'Fever Clinic'
    },
    {
        key:'thermal-guns',
        value:'100+',
        title:'Thermal Guns'
    },
    {
        key:'sanitizer-machines',
        value:'200+',
        title:'Sanitizer Machines'
    },
    {
        key:'ration-kits',
        value:'10,000+',
        title:'Ration Kits'
    },
    {
        key:'food-packets',
        value:'1,00,000+',
        title:'Food Packets'
    },
    
]