import React,  { useState } from 'react';
import './project-arch.style.scss';
import { BsDot } from 'react-icons/bs'

const ProjectArch = ({ title, subtitle1, subtitle2, subtitle3, image ,key, inverted, handleButtonClick }) => {


    return(
        <div className='project-arch-container'  style={{flexDirection: inverted?"row-reverse":'row'}} >
            <div className='project-info-container'>
                <p className='project-title'>{title}</p>
                <div className="subtitle-container">
                    <p className='project-subtitle'>{subtitle1}</p>
                    <p className='project-subtitle'>{subtitle2}</p>
                    <p className='project-subtitle'>{subtitle3}</p>
                </div>
                
                
                
                <button className='know-more button' onClick={handleButtonClick}>Know More...</button>
               
            </div>

           
            <img className='project-image' style={{left: inverted?0: null, right:!inverted?0:null}} src={image} alt={key} />
        </div>
    )
}

export default ProjectArch