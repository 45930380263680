import React from 'react';
import './quotes.style.scss';
import top from '../../assets/top.png';
import bottom from '../../assets/bottom.png';

const Quotes = ({title, content}) => {
    return(
        <div className='quotes-container'>
            <div className='quotes-title-container'>
                <img className='quote-icon top-quote' src={top} alt='top-quote' />
                <p className='quote-title'>{title}</p>
            </div>
            <p className='quote-content'>{content}</p>
            <img className='quote-icon bottom-quote' src={bottom} alt='bottom-quote' />
        </div>
    )
}

export default Quotes