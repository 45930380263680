import React from 'react';
import './covid-package.style.scss';

const CovidPackage = ({ title, subtitle, icon }) => {

    return(
        <div className='covid-package-container'>
            <img className='package-icon' src={icon} alt={title} />
            <p className='package-title'>{title}</p>
            <p className='package-subtitle'>{subtitle}</p>
        </div>
    )
}

export default CovidPackage;