import React, { useState } from 'react';
import './mobile-nativation.style.scss';
import logo from '../../assets/asra_logo.png';
import anniversary from '../../assets/anniversary.png';
import { HiMenuAlt4 } from 'react-icons/hi';
import { Link, useHistory } from 'react-router-dom';


const MobileNavigation = _ => {

    const [ showSideBar, setShowSideBar ] = useState(false);
    const history = useHistory()

    const closeDrawer = () => {
        setShowSideBar(false)
    }

    return(
        <div className='mobile-navigation-container'>
            <img className='anniversary' src={anniversary} alt='asra-samajik-lok-kalyan-samiti'  />
            <img className='logo' src={logo} alt='asra-samajik-lok-kalyan-samiti'  />
            <HiMenuAlt4 size={30} color="#444" onClick={() => {setShowSideBar(!showSideBar)}}  />
            {
                showSideBar
                &&
                <div className='side-bar-container'>
                <div className='grey-area'  onClick={closeDrawer} >
                    
                </div>
                <nav className='nav-container' >
                    <Link className='nav-item' onClick={closeDrawer} to={'/'}>HOME</Link>
                    <a className='nav-item' onClick={closeDrawer} href='/#about-us' >ABOUT US</a>
                    <a className='nav-item' onClick={closeDrawer}  href='/#covid-19' >COVID-19</a>
                    <a className='nav-item' onClick={closeDrawer}  href="/#our-projects" >OUR PROJECTS</a>
                    <Link className='nav-item' onClick={closeDrawer}  to='/governance'>GOVERNANCE</Link>
                    <Link className='nav-item' onClick={closeDrawer}  to='/contact-us'>CONTACT US</Link>
                    <p className='nav-item donate-now' onClick={closeDrawer} >DONATE NOW</p>
                </nav>
                </div>
            }
           

        </div>
    )
}

export default MobileNavigation