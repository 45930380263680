import React from 'react';
import SectionTitle from '../section-title/section-title.component';
import partners from './partners';
import './partners.style.scss';

const Partners = () => {

    return(
        <div className='partners-container'>
            <SectionTitle title="Our Partners" />
            <div className='icon-container'>
                {
                    partners.map(({icon, id}) => <img key={id} className='partner-logo' src={icon} alt={id}  /> )
                }
            </div>
        </div>
    )
}

export default Partners;