import health from '../../assets/health_coverimage.JPG';
import livelihood from "../../assets/livelihood_coverimage.jpg"
import education from "../../assets/education_coverimage.JPG"
import environment from "../../assets/environment_coverimage.jpg"

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        title:"Health",
        subtitle1:'Maternal and Child Health Care',
        subtitle2:'Adolescent & Menstrual Health Care',
        subtitle3:'ASHA Workers Training',
        key:"health",
        image:health,
    },
    {
        title:"Livelihood",
        subtitle1:'Agri Allied Activity',
        subtitle2:'Dairy Development Program',
        subtitle3:'Goat Business Development',
        key:"livelihood",
        image:livelihood,
    },
    {
        title:"Education",
        subtitle1:'Model Anganwadi',
        subtitle2:'School Infrastructure Development Program',
        subtitle3:'Mast Ki Pathshala',
        key:"education",
        image:education,
    },
    {
        title:"Environment & Sanitation",
        subtitle1:'Total Sanitation Program',
        subtitle2:'Solid Waste Management Program',
        subtitle3:'Swachha Gaon Swathya Gaon',
        key:"environment",
        image:environment,
    },   
    
]