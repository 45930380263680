import React, { useEffect, useRef } from 'react'
import "./home-carousel.style.scss"

const HomepageCarouselComponent = (props) => {

    const caroselItem = useRef()
    const { imageUrls } = props

    useEffect(() => {
        const offset = (window.screen.width)
        const sliderFunction = () => {
            try{
                if (caroselItem.current.scrollLeft >= (imageUrls.length - 1) * offset){
                    caroselItem.current.scrollLeft -= imageUrls.length * offset
                }else {
                    caroselItem.current.scrollLeft += offset
                }
            }catch(err){
                console.log("ERROR CATCHED")
            }
            
        }
        setInterval( sliderFunction , 5000)

        return () => {
            clearInterval(sliderFunction)
        }
       
    }, [])

    const imageRenderer = (item) => {
        return(
            <div className='hero-container-one'>
                <div className='carosel-container' style={{backgroundImage: `url(${item.image})`}} >
                    <img className='carosel-image' src={item.image} alt={item.tag}  />
                    <p className='carosel-tag'>{item.tag}</p>
                </div>
            </div>
        )
    }

    return(
        <div className='carosel-container'>
            <div className='image-holder' ref={caroselItem}>
                {
                    imageUrls.map(imageRenderer)
                }
            </div>
        </div>
    )
}

export default HomepageCarouselComponent