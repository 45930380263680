import React, { useEffect, useState } from 'react';
import './navigation.style.scss';
import logo from '../../assets/asra_logo.png';
import anniversary from '../../assets/anniversary.png';
import MobileNavigation from './mobile-navigation.component';
import { Link, useHistory } from 'react-router-dom';


const Navigation = () => {

    
    const [screenSize, setScreenSize] = useState(window.innerWidth);

    const history = useHistory()

    useEffect(() => {
        window.addEventListener('resize', () => {
            setScreenSize(window.innerWidth);
        })
       
    },[])

    if (screenSize <= 1230){
        return <MobileNavigation />
    }

    return(
        <div className='navigaion-container'>
            <div className='logo-container'>
                <div className='logo-menu-container'>
                    <div className='logo-tagline-container'>
                        <img className='logo' src={logo} alt="Asra Samajik Lok Kalyan Samiti" />
                        <p className='tagline'>We owe them a smile...</p>
                    </div>

                    <nav className='nav-container'>
                        <Link className='nav-item' to={'/'}>HOME</Link>
                        <a className='nav-item' href='/#about-us' >ABOUT US</a>
                        <a className='nav-item' href='/#covid-19' >COVID-19</a>
                        <a className='nav-item' href="/#our-projects" >OUR PROJECTS</a>
                        <Link className='nav-item' to='/governance'>GOVERNANCE</Link>
                        <Link className='nav-item' to='/contact-us'>CONTACT US</Link>
                        <p className='nav-item donate-now'>DONATE NOW</p>
                    </nav>
                </div>
                
                <div className='anniversary-container'>
                    <img className='anniversary' src={anniversary} alt='20 years completed' />
                </div>
            </div>

            
        </div>
    )
}

export default Navigation;