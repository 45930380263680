import React, { createRef, useEffect, useState } from 'react';
import Quotes from '../quotes/quotes.component';
import SectionTitle from '../section-title/section-title.component';
import aboutContent from './about-content';
import './about.style.scss';
import asraFamily from '../../assets/asra_family.png';

const About = _ => {

    const imageRef = createRef(null);
    const [captionWidth, setCaptionWidth] = useState(0); 

    useEffect(() => {
       
      setCaptionWidth(imageRef.current.clientWidth);
    }, [])

    return(
        <div className='about-container' id="about-us">
            <SectionTitle  title='About Us' />

            <p className='section-one'>
            ASRA is a Non-Governmental volunteer organization registered under the Society Registration Act 1973, in 2001, working with tireless dedication in the area of Health, Livelihood, Environment and, Community development. The organization is working in collaboration with the government and corporate in three states of India. They are working continuously in 18 districts of Madhya Pradesh.
            </p>

            <p className='section-two'>
            ASRA has been working successfully for 20 years in collaboration with the Government and CSR in fields of social development, social welfare, social upliftment, social self-reliance, social change to help women and children, including a reduction in maternal-infant mortality, adolescent health, HIV AIDS prevention, TB control, training for ASHA, and Anganwadi Worker, sanitation issues and, livelihood promotion programs.
            </p>
            
            {
                aboutContent.map(({title, content, key}) => <Quotes key={key} title={title} content={content} /> )
            }

            <div className='image-container'>
                <img ref={imageRef} className='image-content' src={asraFamily} alt='asra-family' />
                <p style={{width:captionWidth}} className='image-caption'>Asra Family</p>
            </div>
        </div>
    )
}

export default About;