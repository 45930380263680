import nhm from '../../assets/NHM-removebg-preview 1.png';
import ADB from "../../assets/ADB_Partner.png"
import partner3 from "../../assets/partner3.png"
import partner4 from "../../assets/partner4.png"
import partner5 from "../../assets/partner5.png"
import mpsacs from '../../assets/MPSacs-removebg-preview 1.png'
import partner6 from "../../assets/partner6.png"
import partner7 from "../../assets/partner7.png"
import basix from "../../assets/basix_partner.png"
import partner8 from "../../assets/partner8.png"
import partner9 from "../../assets/partner9.png"
import tata from '../../assets/TATA-removebg-preview 1.png';
import partner10 from "../../assets/partner10.png"
import apollo from '../../assets/appolo-removebg-preview 1.png';
import zomato from '../../assets/zomato.png';
import partner11 from "../../assets/partner11.png"
import partner12 from "../../assets/partner12.png"
import HDFC from "../../assets/HDFC.png"
import partner13 from "../../assets/partner13.png"
import clp from '../../assets/clp_india_final-removebg-preview 1.png';
import gamesa from '../../assets/gamesa.png';
import aif from '../../assets/aif.png';
import usaid from '../../assets/usaid.png';
import partner14 from "../../assets/partner14.png"
import partner15 from "../../assets/partner15.png"
import partner16 from "../../assets/partner16.png"
import partner17 from "../../assets/partner17.png"


// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        name:'NHM',
        icon:nhm,
        id:'nhm'
    },
    {
        name:'ADB',
        icon:ADB,
        id:'ADB'
    },
    {
        name:'partner3',
        icon:partner3,
        id:'partner3'
    },
    {
        name:'partner4',
        icon:partner4,
        id:'partner4'
    },
    {
        name:'partner5',
        icon:partner5,
        id:'partner5'
    },
    {
        name:'mpsacs',
        icon:mpsacs,
        id:'mpsacs'
    },
    {
        name:'partner6',
        icon:partner6,
        id:'partner6'
    },
    {
        name:'partner7',
        icon:partner7,
        id:'partner7'
    },
    {
        name:'basix',
        icon:basix,
        id:'basix'
    },
    {
        name:'partner8',
        icon:partner8,
        id:'partner8'
    },
    {
        name:'partner9',
        icon:partner9,
        id:'partner9'
    },
    {
        name:'tata',
        icon:tata,
        id:'tata'
    },
    {
        name:'partner10',
        icon:partner10,
        id:'partner10'
    },
    {
        name:'apollo',
        icon:apollo,
        id:'apollo'
    },
    {
        name:'zomato',
        icon:zomato,
        id:'zomato'
    },
    {
        name:'partner11',
        icon:partner11,
        id:'partner11'
    },
    {
        name:'partner12',
        icon:partner12,
        id:'partner12'
    },
    {
        name:'HDFC',
        icon:HDFC,
        id:'HDFC'
    },
    {
        name:'partner13',
        icon:partner13,
        id:'partner13'
    },
    {
        name:'clp',
        icon:clp,
        id:'clp'
    },
    {
        name:'gamesa',
        icon:gamesa,
        id:'gamesa'
    },
    {
        name:'aif',
        icon:aif,
        id:'aif'
    },
    {
        name:'usaid',
        icon:usaid,
        id:'usaid'
    },
    {
        name:'partner14',
        icon:partner14,
        id:'partner14'
    },
    {
        name:'partner15',
        icon:partner15,
        id:'partner15'
    },
    {
        name:'partner16',
        icon:partner16,
        id:'partner16'
    },
    {
        name:'partner17',
        icon:partner17,
        id:'partner17'
    }
]