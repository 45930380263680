import React, { useRef, useEffect } from 'react';
import './carousel.style.scss'


const Carousel = (props) => {
    const caroselItem = useRef()
    const { imageUrls } = props


    useEffect(() => {
        const offset = (.97 * window.screen.width) - 50
        const sliderFunction = () => {
            try{
                if (caroselItem.current.scrollLeft >= (imageUrls.length - 2) * offset){
                    caroselItem.current.scrollLeft -= imageUrls.length * offset
                }else {
                    caroselItem.current.scrollLeft += offset
                }
            }catch(err){
                console.log("ERROR CATCHED")
            }
            
        }
        setInterval( sliderFunction , 5000)

        return () => {
            clearInterval(sliderFunction)
        }
       
    }, [])

    const imageRenderer = (item) => {
        return(
            <div className='carosel-image-container'  >
                <img className='carosel-image' key={item} src={item} alt={item}   />
            </div>
        )
    }
    
    return(
        <div className='carousel-container'>
            <div className='image-holder' ref={caroselItem}>
                {
                    imageUrls.map(imageRenderer)
                }
            </div>
        </div>
    )
}

export default Carousel