import React, { useEffect, useRef, useState } from 'react';
import Carousel from '../../components/carosel/carousel.component';
import Navigation from '../../components/Navigation/navigation.component';
import SectionTitle from '../../components/section-title/section-title.component';
import { firebaseDatabase } from '../../firebase/firebase-handler';
import projectArch from './project-arch';
import './project-details.style.scss';


const ProjectDetails = (props) => {
    const  {projectId}  = props.match.params;
    //const {meta, sections} =  projectArch;
    const [meta, setMeta] = useState({})
    const [sections, setSections] = useState([])
    const container = useRef();
    
    useEffect(() => {
        window.scrollTo(0, 0)
        const {projectId}  = props.match.params;

        console.log(projectId)
        
        firebaseDatabase.ref("PROJECT_DETAILS").child(projectId).once("value", (dataSnapshot) => {
            if(dataSnapshot.exists()) {
                setMeta(dataSnapshot.child("meta").val())
                setSections(dataSnapshot.child("sections").val())
            }
        })
    }, [])

    return(
        <div className='project-list-container' ref={container} >
            <Navigation />
            
            <div className='sectors-container' style={{backgroundImage:`url(${meta.projectHeaderUrl})`, backgroundRepeat:'no-repeat'}} >
                <p className='project-sector-name'>{meta.projectSector}</p>
                <p className='project-sector-tagline'>{meta.projectTagLine}</p>
                <div className='project-name-lists'>
                    {
                        sections.map(({projects}) => {
                            return projects.map(project => {
                                return <a key={project.title} className='project-name-link' href={`#${project.key}`}>{project.title}</a>
                            })
                        })
                    }
                </div>
            </div>

            <div className='projects-container'>
                {
                    sections.map((sectors) => {
                        return sectors.projects.map(({title, content, key, headerImage}) => {
                            return(
                                <div className='sector-project-container'>
                                    <SectionTitle title={title} hyperLinkTo={key} />
                                    {
                                        headerImage
                                        &&
                                        <Carousel imageUrls={[...headerImage, ...headerImage]} />
                                    }
                                    {
                                        content.map(({ paragraph, bullet }, index) => {
                                            return(
                                                <div className='project-detail-container' key={index.toString()} >
                                                    {paragraph && <p className='project-paragraph'>{paragraph}</p>}
                                                    {
                                                    bullet 
                                                    && 
                                                    <div className='bullet-container'>
                                                        {
                                                            bullet.title
                                                            &&
                                                            <p className='bullet-title'>{bullet.title}</p>
                                                        }
                                                        {
                                                            bullet.points
                                                            &&
                                                            <ul className='list-container'>
                                                            {
                                                                bullet.points.map((item) => {
                                                                    return <li className='list-item'>{item}</li>
                                                                })
                                                            }
                                                            </ul>
                                                        }
                                                        
                                                    </div>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    })
                }
            </div>

        </div>
    )
}


export default ProjectDetails;