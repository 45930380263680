import React from 'react';
import './covid-stats.styles.scss';

const CovidStats = ({value, title}) => {
    return(
        <div className='covid-stats-content'>
            <p className='stats-value'>{value}</p>
            <p className='stats-title'>{title}</p>
        </div>
    )
}

export default CovidStats