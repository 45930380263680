import React, { useState } from 'react'
import './pdfviewer.style.scss';


const PdfView = (props) => {

  
    return(
        <div className='pdf-viewer-container'>
            <embed src={props.location.state} width="100%" height="100%" 
            type="application/pdf"></embed>
        </div>
    )
}

export default PdfView;